<template>
  <main class="page page-contacts">
    <BreadcrumbsComponent title="Контакты" />
    <div class="page__container">
      <div class="page__content">
        <div class="page-contacts__list">
          <div>
            <div class="page-contacts__links">
              <a v-if="department.phone" :href="`tel:${department.phone}`">
                <b>Телефон: </b>{{ department.phone }}
              </a>
              <a v-if="department.fax" :href="`tel:${department.fax}`"><b>Факс: </b>{{ department.fax }}</a>
              <a v-if="department.email" :href="`mailto:${department.email}`">
                <b>E-mail: </b>{{ department.email }}
              </a>
              <a v-if="department.phone_media" :href="`tel:${department.phone_media}`">
                <b>Телефон СМИ: </b>{{ department.phone_media }}
              </a>
              <a v-if="department.email_media" :href="`mailto:${department.email_media}`">
                <b>E-mail СМИ: </b>{{ department.email_media }}
              </a>
            </div>
            <div v-if="department.schedule">
              <b>Режим работы:</b>
              <pre>{{ department.schedule }}</pre>
            </div>
          </div>
          <div>
            <div v-if="department.address">
              <b>Адрес:</b>
              <pre>{{ department.address }}</pre>
            </div>
            <div class="page-contacts__socials" v-if="department.socials">
              <a :href="link.link" target="_blank" v-for="(link, i) in department.socials" :key="i">
                <IconComponent category="default" :name="link.type.code | socialIconName" />
              </a>
            </div>
          </div>
        </div>
        <div class="page-contacts__map">
          <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
            <ymap-marker marker-id="0" :coords="coords" />
          </yandex-map>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import DEPARTMENT from "gql/queries/department.graphql";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "ContactsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      mapReady: false,
      coords: [0, 0],
      debounceInterval: null,
      settings: {
        // apiKey: "1c628e67-168f-457a-a471-45964accfcfa",
        // apiKey: "e98c2660-3653-4715-adcf-585d58d494ee",
        apiKey: "1068c62e-aae1-4be2-a3a1-a17faa653b04",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  methods: {
    initMap() {
      loadYmap(this.settings).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(this.department.address).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
            this.mapReady = true;
          });
        });
      });
    },
  },
  mounted() {
    this.initMap();
    this.debounceInterval = setInterval(() => {
      window.addEventListener("resize", () => {
        this.mapReady = false;
        this.$nextTick(() => {
          this.initMap();
        });
      });
    }, 500);
  },
  computed: {
    department() {
      return this.$store.state.department;
    },
  },
  metaInfo: {
    title: "Контакты",
  },
  components: {
    IconComponent,
    BreadcrumbsComponent,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
.page-contacts {
  display flex
  flex-direction column
  align-items center

  &__list {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 20px
    +below(1280px) {
      grid-template-columns 1fr
      font-size: 0.875em;
      line-height: 22px;
    }

    > * {
      display grid
      grid-gap 30px
      align-content start
    }

    pre {
      font-weight: 500;
      font-size: 1em
      line-height: 26px;
      color: var(--dark);
      white-space pre-line
      +below(1280px) {
        font-size: 0.875em;
        line-height: 22px;
      }
    }
  }

  &__links {
    display grid
    gap 10px
    +below(1280px) {
      gap 5px
    }

    a {
      font-weight: 500;
      font-size: 1em
      line-height: 26px;
      color: var(--dark);
    }
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      border: 1px solid var(--dark-o1);
      border-radius: 100px;
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 22px
        max-height: 22px

        svg path {
          fill var(--main)
        }
      }
    }
  }

  &__map {
    height 450px
    border-radius 10px
    overflow hidden

    > * {
      height 100%
    }
  }
}
</style>
